var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8f853f5bbef7b0c8aefc20b855d872fb8bedf4c1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

function scrubPIIFromBreadcrumb(breadcrumb) {
  if (breadcrumb.data) {
    // Redact sensitive data fields
    if (breadcrumb.data.token) breadcrumb.data.token = '[REDACTED]';
    if (breadcrumb.data.password) breadcrumb.data.password = '[REDACTED]';
  }
  if (breadcrumb.message) {
    // Redact sensitive information in messages
    breadcrumb.message = breadcrumb.message.replace(/(token=)[^&]+/, '$1[REDACTED]');
    breadcrumb.message = breadcrumb.message.replace(/(password=)[^&]+/, '$1[REDACTED]');
  }
  if (breadcrumb.category === 'navigation' && breadcrumb.data) {
    if (breadcrumb.data.to) {
      breadcrumb.data.to = '/#[REDACTED]';
    }
    if (breadcrumb.data.from) {
      breadcrumb.data.from = '/#[REDACTED]';
    }
  }
  return breadcrumb;
}

function scrubPIIFromEvent(event) {
  if (event.breadcrumbs) {
    event.breadcrumbs = event.breadcrumbs.map(scrubPIIFromBreadcrumb);
  }
  return event;
}

Sentry.init({
  dsn: 'https://cd7d9a81670c7f51070bc500a192237e@o46236.ingest.sentry.io/4506507566776320',

  enabled: process.env.NODE_ENV === 'production',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Scrub URL fragments from the request URL
  beforeSend(event) {
    return scrubPIIFromEvent(event);
  },
});
